import { userService } from "../apis";
import axios from "../apis/axios";
import i18n from "../translation";

const userToken = localStorage.getItem("userToken");
const user = localStorage.getItem("email");
const permissions = JSON.parse(localStorage.getItem("permissions"));
// let actionDeletedMsg = localStorage.getItem('locale') === 'en' ? "" : ""
let loggedInMsg = localStorage.getItem('locale') === 'en' ? "you are successfully logged in as" : "Je bent ingelogd als"
let errAddingUserMsg = localStorage.getItem('locale') === 'en' ? "Error while adding user" : "Fout bij het toevoegen van de gebruikers"
let measurementFetchMsg = localStorage.getItem('locale') === 'en' ? "Successfully fetched measurements" : "Maatregelen opgehaald"
let partnerUpdateSuccessMsg = localStorage.getItem('locale') === 'en' ? "Partner updated successfully" : "Partner is aangepast"
let housepasspoerCreationSuccessMsg = localStorage.getItem('locale') === 'en' ? "House Passport successfully created" : "Woningpaspoort aangemaakt"
let EnergyReportCreationSuccessMsg = localStorage.getItem('locale') === 'en' ? "Energy Report successfully created" : "Energierapport succesvol aangemaakt"
if (localStorage.getItem("role")) {
    localStorage.clear();
    window.location.reload();
}
const initialState = userToken
    ? {
        status: { loggedIn: true },
        user: {
            token: userToken,
            email: user,
            permissions,
        },
        usersList: {},
        houseTypes: [],
        isLoading: false,
        cityDash: {
            currentPage: 1,
            filters: {},
            selectedCategory: {},
            selectedAction: {},
            selectedMeasurement: {},
            selectedYear: "",
            selectedCity: {},
            fromDate: "",
            toDate: "",
        },
        contactsFilter: {
            currentPage: 1,
            email: "",
            postalCode: "",
            houseNumber: "",
            toevoeging: "",
        },
        energyReportFilters: {
            currentPage: 1,
            email: "",
            phoneNumber: ""
        },
        houseHash: "",
        leadDash: {
            currentPage: 1,
            filters: {},
            selectedFilterPartner: {},
            selectedFilterMeasurement: {},
            selectedFilterBrand: {},
            selectedFilterModel: {},
            dateFilter: "",
            selectedFilterAction: {},
            selectedFilterStatus: {},
            filterHouseNumber: "",
            filterPostalCode: "",
            filterToevoeging: "",
        },
        partnerDash: {
            currentPage: 1,
            filters: {},
            selectedMeasure: {},
            selectedModel: {},
            selectedBrand: {},
        },
    }
    : {
        status: { loggedIn: false },
        user: { email: "", token: "", permissions: {}, group: {} },
        usersList: {},
        isLoading: false,
        houseTypes: [],
        cityDash: {
            currentPage: 1,
            filters: {},
            selectedCategory: {},
            selectedAction: {},
            selectedMeasurement: {},
            selectedYear: "",
            selectedCity: {},
            fromDate: "",
            toDate: "",
        },
        leadDash: {
            currentPage: 1,
            filters: {},
            selectedFilterPartner: {},
            selectedFilterMeasurement: {},
            selectedFilterBrand: {},
            selectedFilterModel: {},
            dateFilter: "",
            selectedFilterAction: {},
            selectedFilterStatus: {},
            filterHouseNumber: "",
            filterPostalCode: "",
            filterToevoeging: "",
        },
        partnerDash: {
            currentPage: 1,
            filters: {},
            selectedMeasure: {},
            selectedModel: {},
            selectedBrand: {},
        },
    };

const actions = {
    locationPartnersExportAction({ commit, dispatch }, payload) {
        return userService.locationPartnersExport(payload).then(
            (res) => {
                commit("ignoringCommit");
                const d = new Date();
                const text = d.toLocaleDateString();
                const href = URL.createObjectURL(res.res.data);
                const anchorElement = document.createElement("a");
                anchorElement.id = "excel";
                anchorElement.setAttribute("href", href);
                anchorElement.setAttribute(
                    "download",
                    `LocationPartners_${text}_export.xlsx`
                );
                document.body.appendChild(anchorElement);
                anchorElement.click();
                document.body.removeChild(anchorElement);
                dispatch("alert/success", `Location Partners generated successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch("alert/error", `You need to select Location.`, {
                    root: true,
                });
                return error;
            }
        );
    },
    createTermsAndConditionAction({ dispatch }, payload) {
        return userService.createTermsAndCondition(payload).then(
            (res) => {
                dispatch("alert/success", `Terms And Condition successfully added.`, {
                    root: true,
                });
                return res;
            },

            (err) => {
                dispatch("alert/error", `Error while adding Terms And Condition.`, {
                    root: true,
                });
                return err;
            }
        );
    },
    updateTermsAndConditionAction({ commit, dispatch }, reqData) {
        return userService.updateTermsAndCondition(reqData).then(
            (res) => {
                dispatch("alert/success", `Terms And Condition updated successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot update Terms And Condition, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },

    getTermsAndConditionAction({ commit }, reqData) {
        return userService.getTermsAndCondition(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    getTermsAndConditionOfIDAction({ commit }, reqData) {
        return userService.getTermsAndConditionOfID(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },

    checkTermsAndConditionAction({ commit }) {
        return userService.checkTermsAndCondition().then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },

    getActiveTermsAndConditionAction({ commit }) {
        return userService.getActiveTermsAndCondition().then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },

    acceptTermsAndConditionAction({ commit, dispatch },payload) {
        return userService.acceptTermsAndCondition(payload).then(
            (res) => {
                dispatch(
                    "alert/success",
                    `Terms And Condition accepted successfully.`,
                    {
                        root: true,
                    }
                );
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `not accepted Terms And Condition, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    getPartnersTermsAndConditionAction({ commit }, reqData) {
        return userService.getPartnersTermsAndCondition(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },

    // LOCATION OVERVIEW PAGE ACTIONS START

    // parterns with most leads and measures action
    PartnersWithMostLeadsAndMesuresAction({ commit }, reqData) {
        return userService.getPartnersWithMostLeadsAndMesures(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },

    // lead fees and revenue action
    LeadFeesAndRevenueAction({ commit }, reqData) {
        return userService.getLeadFeesAndRevenue(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },

    // overview page cards action
    OverviewCardsAction({ commit }, reqData) {
        return userService.getOverviewCardsDetails(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },

    // overview page Partner with highest Rating action
    PartnersWithMostReviewAction({ commit }, reqData) {
        return userService.getPartnersWithMostReview(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    LocationAverageReviewAction({ commit }, reqData) {
        return userService.getLocationAverageReview(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },


    // LOCATION OVERVIEW PAGE ACTIONS END

    // CITY OVERVIEW PAGE ACTIONS START

    // measure with most leads and mesures action
    MeasuresWithMostLeadsAndMesuresAction({ commit }) {
        return userService.getMeasuresWithMostLeadsAndMesures().then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },



    CityOverviewCardsAction({ commit }, reqData) {
        return userService.getCityOverviewCards(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },

    LeadsVsOrdersAndInvestmentAction({ commit }, reqData) {
        return userService.getLeadsVsOrdersAndInvestment(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },

    // CITY OVERVIEW PAGE ACTIONS END

    setCityDashAction({ commit }, reqData) {
        commit("setCityDash", reqData);
    },
    setLeadDashAction({ commit }, reqData) {
        commit("setLeadDash", reqData);
    },
    setPartnerDashAction({ commit }, reqData) {
        commit("setPartnerDash", reqData);
    },
    setContactsFilterDashAction({ commit }, reqData) {
        commit("setContactsFilter", reqData);
    },
    getHouseTypes({ commit }) {
        return userService.getHouseTypes().then(
            (res) => {
                commit("setHouseTypes", res);
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    loginUserAction({ commit, dispatch }, reqData) {
        return userService.loginUser(reqData).then(
            (res) => {
                commit("setLoginSuccess", res.res.data);
                commit("setPermisions", res.res.data);
                const successMessage = i18n.t('message.login_success', {
                    email: res.res.data.user.email,
                    role: res.res.data.user.group.name,
                });
                dispatch("alert/success", successMessage, { root: true });
                return res;
            },
            (err) => {
                // userService.logout();
                commit("setLoginFailure");
                if (!err.isSuccess && err.res.status === 400){
                    dispatch("alert/error", i18n.t('message.login_error'), {
                        root: true,
                    });
                    return err;

                }
                else if (!err.isSuccess && "non_field_errors" in err.res.data) {
                    dispatch("alert/error", err.res.data.non_field_errors[0], {
                        root: true,
                    });
                }
                return err;
            }
        );
    },
    createUserAction({ dispatch }, payload) {
        return userService.createUser(payload).then(
            (res) => {
                dispatch("alert/success", `User successfully added.`, { root: true });
                return res;
            },

            (err) => {
                dispatch("alert/error", errAddingUserMsg, { root: true });
                return err;
            }
        );
    },
    editUserAction({ commit }, payload) {
        return userService.editUser(payload).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },

            (err) => {
                return err;
            }
        );
    },
    forgotPasswordAction({ dispatch }, payload) {
        return userService.forgotPassword(payload).then(
            (res) => {
                dispatch("alert/success", `Please check your email`, { root: true });
                return res;
            },
            (res) => {
                return res;
            }
        );
    },
    resetPasswordAction({ dispatch }, payload) {
        return userService.resetPassword(payload).then(
            (res) => {
                dispatch("alert/success", `Successfully updated your password`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                if (error.res.data.new_password2) {
                    dispatch(
                        "alert/error",
                        `Error setting password, ${error.res.data.new_password2}`,
                        {
                            root: true,
                        }
                    );
                } else {
                    dispatch(
                        "alert/error",
                        `This link is expired,please try resetting you password again or contact WoonWijzerWinkel .`,
                        {
                            root: true,
                        }
                    );
                }
                return error;
            }
        );
    },
    logoutUserAction({ commit, state }) {
        // remove user from local storage to log user out
        return userService.logout().then(
            () => {
                commit("ignoringCommit");
                state.status.loggedIn = false;
                state.user.permissions = {};
                localStorage.removeItem("userToken");
                localStorage.removeItem("email");
                localStorage.removeItem("permissions");
                localStorage.removeItem("cityid");
                localStorage.removeItem("locale");
                localStorage.removeItem("group");
                localStorage.removeItem("userName");
                localStorage.removeItem("userInitial");
                localStorage.removeItem("locationid");
                localStorage.removeItem("locationName");
                localStorage.removeItem("pdfjs.history");
                localStorage.removeItem("locations");
                localStorage.removeItem("actionUser");
                localStorage.removeItem("projectUser");
                localStorage.removeItem("locationFilter");
                localStorage.removeItem("proformaleadid");
                delete userService.axios.defaults.headers.common.Authorization;
                window.location.reload();
            },

            () => {
                commit("ignoringCommit");
            }
        );
    },
    getUserListAction({ commit }, queryParams) {
        return userService.getUserList(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },

    retriveUserAction({ commit }, userId) {
        return userService.getUser(userId).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    deleteUserAction({ dispatch }, userId) {
        return userService.deleteUser(userId).then(
            (res) => {
                dispatch("alert/success", `User Successfully Deleted.`, { root: true });
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getPermissionsListAction() {
        return userService.getPermissionsList().then(
            (res) => {
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getFilteredPermissionsListAction({ commit }, queryParams) {
        return userService.getFilteredPermissionsList(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getGroupsListAction({ commit }, queryParams) {
        return userService.getGroupsList(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getCityGroupsListAction({ commit }, queryParams) {
        return userService.getCityGroupsList(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getAllGroupsListAction() {
        return userService.getAllGroupsList().then(
            (res) => {
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getGroupAction({ commit }, reqData) {
        commit("isLoading", true);
        return userService.getGroup(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getCityGroupAction({ commit }, reqData) {
        commit("isLoading", true);
        return userService.getCityGroup(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    createGroupAction({ dispatch }, reqData) {
        return userService.createGroup(reqData).then(
            (res) => {
                dispatch("alert/success", `Group successfully created.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch("alert/error", `Error creating group`, { root: true });
                return error;
            }
        );
    },
    createCityGroupAction({ dispatch }, reqData) {
        return userService.createCityGroup(reqData).then(
            (res) => {
                dispatch("alert/success", `Group successfully created.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch("alert/error", `Error creating group`, { root: true });
                return error;
            }
        );
    },
    updateGroupAction({ dispatch }, reqData) {
        return userService.updateGroup(reqData).then(
            (res) => {
                dispatch("alert/success", `Group updated successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot update group, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    updateCityGroupAction({ dispatch }, reqData) {
        return userService.updateCityGroup(reqData).then(
            (res) => {
                dispatch("alert/success", `Group updated successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot update group, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    deleteGroupAction({ dispatch }, reqData) {
        return userService.deleteGroup(reqData).then(
            (res) => {
                dispatch("alert/success", `Group deleted successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot delete group, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    deleteCityGroupAction({ dispatch }, reqData) {
        return userService.deleteCityGroup(reqData).then(
            (res) => {
                dispatch("alert/success", `Group deleted successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot delete group, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },

    getCategoriesListAction({ commit }, queryParams) {
        return userService.getCategoriesList(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getAllCategoriesAction() {
        return userService.getAllCategories().then(
            (res) => {
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getMeasureListAction({ commit }, queryParams) {
        return userService.getMeasure(queryParams).then(
            (res) => {
                commit("ignoringCommit");

                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    createCategoryAction({ dispatch }, reqData) {
        return userService.createCategory(reqData).then(
            (res) => {
                dispatch("alert/success", `Category successfully created.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot create category, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    updateCategoryAction({ dispatch }, reqData) {
        return userService.updateCategory(reqData).then(
            (res) => {
                dispatch("alert/success", `Category updated successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot update group, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    deleteCategoryAction({ dispatch }, reqData) {
        return userService.deleteCategory(reqData).then(
            (res) => {
                dispatch("alert/success", `Category deleted successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot delete category, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    getcityDashboardLeadsListAction({ commit }, queryParams) {
        return userService.getCityDashboardLeadsList(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getLeadsListAction({ commit }, queryParams) {
        return userService.getLeadsList(queryParams).then(
            (res) => {
                commit("ignoringCommit");

                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getLeadsListChartsAction({ commit }, queryParams) {
        return userService.getLeadsListCharts(queryParams).then(
            (res) => {
                commit("ignoringCommit");

                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getCityDashboardLeadsAction({ commit }, reqData) {
        commit("isLoading", true);
        return userService.getCityDashboardLead(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                return error;
            }
        );
    },

    createCityDashboardLeadsAction({ dispatch }, reqData) {
        return userService.createCityDashboardLead(reqData).then(
            (res) => {
                dispatch("alert/success", `Lead successfully created.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot create lead, ${
                        error.res.data.lead_created_date || error.res.data.zipcode
                    }.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    createLeadsAction({ dispatch }, reqData) {
        return userService.createLead(reqData).then(
            (res) => {
                dispatch("alert/success", `Lead successfully created.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    updateCityDashboardLeadsAction({ dispatch }, reqData) {
        return userService.updateCityDashboardLead(reqData).then(
            (res) => {
                dispatch("alert/success", `Lead updated successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot create lead, ${
                        error.res.data.lead_created_date || error.res.data.zipcode
                    }.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    updateLeadsAction({ dispatch }, reqData) {
        return userService.updateLead(reqData).then(
            (res) => {
                dispatch("alert/success", `Lead updated successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    deleteCityDashboardLeadAction({ dispatch }, id) {
        return userService.deleteCityDashboardLead(id).then(
            (res) => {
                dispatch("alert/success", `Lead deleted successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot delete group, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },

    updateSpecialLeadsAction({ dispatch }, reqData) {
        return userService.updateSpecialLead(reqData).then(
            (res) => {
                dispatch("alert/success", `Lead updated successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getAllCategoryListAction() {
        return userService.getAllCategoryList().then(
            (res) => {
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getAllMeasureListAction() {
        return userService.getAllMeasureList().then(
            (res) => {
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getAllMeasureCategoryListAction({ commit }, reqData) {
        return userService.getAllMeasureCategoryList(reqData).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getAllModelBrandListAction({ commit }, reqData) {
        return userService.getAllModelBrandList(reqData).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getAllMeasureBrandAction({ commit }, reqData) {
        return userService.getAllMeasureBrand(reqData).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getAllStatusListAction() {
        return userService.getAllStatusList().then(
            (res) => {
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getAllActionListAction() {
        return userService.getAllActionList().then(
            (res) => {
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getAllQueryAction({ commit }, reqData) {
        return userService.getAllQuery(reqData).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    saveCustomerQueryAnswerAction({ commit }, reqData) {
        return userService.saveCustomerQueryAnswer(reqData).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getAllYearsListAction() {
        return userService.getAllYearList().then(
            (res) => {
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    createMeasureAction({ dispatch }, reqData) {
        return userService.createMeasure(reqData).then(
            (res) => {
                dispatch("alert/success", `Measure successfully created.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot create measure, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    updateMeasureAction({ dispatch }, reqData) {
        return userService.updateMeasure(reqData).then(
            (res) => {
                dispatch("alert/success", `Measure updated successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot update measure, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    deleteMeasureAction({ dispatch }, reqData) {
        return userService.deleteMeasure(reqData).then(
            (res) => {
                dispatch("alert/success", `Measure deleted successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot delete measure, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },

    retriveMeasureAction({ commit }, userId) {
        return userService.getMeasure(userId).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    uploadExcelFileAction({ dispatch, commit }, payload) {
        commit("isLoading", true);
        return userService.excelCityDashboardLead(payload).then(
            (res) => {
                commit("isLoading", false);
                dispatch("alert/success", `Successfully added records.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                commit("isLoading", false);
                return error;
            }
        );
    },
    getCitiesListAction({ dispatch }, queryParams) {
        return userService.getCitiesList(queryParams).then(
            (res) => {
                return res;
            },
            (error) => {
                dispatch("alert/error", `No data found at page`, { root: true });
                return error;
            }
        );
    },
    getAllCitiesListAction({ commit }) {
        return userService.getAllCitiesList().then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getAllCitiesGroupsListAction({ commit }) {
        return userService.getAllCitiesGroupList().then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getAllDistinctCitiesListAction({ commit }) {
        return userService.getAllDistinctCitiesList().then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    createCityAction({ dispatch }, reqData) {
        return userService.createCity(reqData).then(
            (res) => {
                dispatch("alert/success", `City successfully created.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch("alert/error", `Cannot create city, ${error.res.data.name}.`, {
                    root: true,
                });
                return error;
            }
        );
    },
    updateCityAction({ dispatch }, reqData) {
        return userService.updateCity(reqData).then(
            (res) => {
                dispatch("alert/success", `City updated successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch("alert/error", `Cannot update city, ${error.res.data.name}.`, {
                    root: true,
                });
                return error;
            }
        );
    },
    getCityAdminListAction({ commit }, queryParams) {
        const { id } = queryParams;
        // eslint-disable-next-line no-param-reassign
        delete queryParams.id;
        return userService.getCityAdminList(queryParams, id).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    sendPasswordGeneratinglinkAction({ commit, dispatch }, queryParams) {
        return userService.sendPasswordGeneratinglink(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                dispatch(
                    "alert/success",
                    `Password generating mail sent successfully.`,
                    {
                        root: true,
                    }
                );
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    addCityAdminAction({ dispatch }, payload) {
        const { id } = payload;
        // eslint-disable-next-line no-param-reassign
        delete payload.id;
        return userService.addCityAdmin(payload, id).then(
            (res) => {
                dispatch("alert/success", `City admin successfully added.`, {
                    root: true,
                });
                return res;
            },

            (err) => {
                return err;
            }
        );
    },
    deleteCityAdminAction({ dispatch }, reqData) {
        const id = reqData.cityid;
        // eslint-disable-next-line no-param-reassign
        delete reqData.cityid;
        return userService.deleteCityAdmin(reqData, id).then(
            (res) => {
                dispatch("alert/success", `City admin removed successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch("alert/error", `Cannot remove city admin`, { root: true });
                return error;
            }
        );
    },
    exportExcelFileAction({ dispatch, commit }) {
        commit("isLoading", true);
        let successMsg = localStorage.getItem('locale')==='en' ? "Email will be sent when the exported file is ready" : "Zodra de export gereed is ontvangt u een e-mail"

        return userService.exportExcelFile().then(
            () => {
                commit("isLoading", false);
                dispatch(
                    "alert/success",
                    `${successMsg}`,
                    {
                        root: true,
                    }
                );
            },
            () => {
                commit("isLoading", false);
                dispatch("alert/error", `Error exporting file`, { root: true });
            }
        );
    },

    exportLeadsAction({ dispatch, commit }, queryParams) {
        commit("isLoading", true);

        let isLoading = localStorage.getItem('locale')==='en' ? "The download is processing" : "De download wordt verwerkt"
        dispatch(
            "alert/success",
            `${isLoading}`,
            {
                root: true,
            }
        );

        let successMsg = localStorage.getItem('locale')==='en' ? "Email will be sent when the exported file is ready" : "Zodra de export gereed is ontvangt u een e-mail"

        return userService.downloadExcelLeads(queryParams).then(
            (res) => {
                commit("isLoading", false);
                if (res.res.data) {
                    const d = new Date();
                    const text = d.toLocaleDateString();
                    const href = URL.createObjectURL(res.res.data);
                    const anchorElement = document.createElement("a");
                    anchorElement.id = "excel";
                    anchorElement.setAttribute("href", href);
                    anchorElement.setAttribute(
                        "download",
                        `leads_${text}_export.xlsx`
                    );
                    document.body.appendChild(anchorElement);
                    anchorElement.click();
                    document.body.removeChild(anchorElement);

                    let downloadMsg =  localStorage.getItem('locale')==='en' ? "Excel is been downloaded1" : "Excel is been downloaded"

                    dispatch("alert/success", downloadMsg, {
                        root: true,
                    });
                } else {
                    dispatch(
                        "alert/success",
                        `${successMsg}`,
                        {
                            root: true,
                        }
                    );
                }
            },
            () => {
                commit("isLoading", false);

                let errorMsg = localStorage.getItem('locale')==='en' ? "Sorry we can only download excel when number of leads are less then 5000" : "Je kan maximaal 5000 leads exporteren. Pas de filters aan om de dataset kleiner te maken"

                dispatch(
                    "alert/information",
                    errorMsg,
                    { root: true }
                );
            }
        );
    },
    exportUsersAction({ dispatch, commit }, queryParams) {
        commit("isLoading", true);

        let successMsg = localStorage.getItem('locale')==='en' ? "Email will be sent when the exported file is ready" : "Zodra de export gereed is ontvangt u een e-mail"

        return userService.exportUsers(queryParams).then(
            () => {
                commit("isLoading", false);
                dispatch(
                    "alert/success",
                    `${successMsg}`,
                    {
                        root: true,
                    }
                );
            },
            () => {
                commit("isLoading", false);
                dispatch("alert/error", `Error exporting file`, { root: true });
            }
        );
    },
    exportHousePassportAction({ dispatch, commit }, queryParams) {
        commit("isLoading", true);
        let successMsg = localStorage.getItem('locale')==='en' ? "Email will be sent when the exported file is ready" : "Zodra de export gereed is ontvangt u een e-mail"

        return userService.exportHousePassport(queryParams).then(
            () => {
                commit("isLoading", false);
                dispatch(
                    "alert/success",
                    `${successMsg}`,
                    {
                        root: true,
                    }
                );
            },
            () => {
                commit("isLoading", false);
                dispatch("alert/error", `Error exporting file`, { root: true });
            }
        );
    },

    //Export Groups
    exportGroupAction({ dispatch, commit }, queryParams) {
        commit("isLoading", true);

        return userService.exportGroups(queryParams).then(
            (res) => {
                commit("isLoading", false);

                if (res && res.res.data) { // Verify res and res.res.data are defined
                    const d = new Date();
                    const text = d.toLocaleDateString();
                    const href = URL.createObjectURL(new Blob([res.res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));

                    const anchorElement = document.createElement("a");
                    anchorElement.setAttribute("href", href);
                    anchorElement.setAttribute(
                        "download",
                        `Groups_${text}_export.xlsx`
                    );
                    document.body.appendChild(anchorElement);
                    anchorElement.click();
                    document.body.removeChild(anchorElement);
                    URL.revokeObjectURL(href); // Revoke the URL object

                    dispatch("alert/success", "Excel has been downloaded", {
                        root: true,
                    });
                } else {
                    let successMsg = localStorage.getItem('locale') === 'en'
                        ? "Email will be sent when the exported file is ready"
                        : "Zodra de export gereed is ontvangt u een e-mail";

                    dispatch("alert/success", successMsg, { root: true });
                }
            },
            (error) => {
                commit("isLoading", false);
                dispatch(
                    "alert/information",
                    "Sorry, we can only download excel when the number of leads is less than 5000",
                    { root: true }
                );
            }
        ).catch((error) => {
            commit("isLoading", false);
            dispatch(
                "alert/error",
                "An error occurred while exporting groups.",
                { root: true }
            );
            console.error("Export error:", error); // Log the error for debugging
        });
    },




    downloadExcelLeadsAction({ dispatch, commit }, queryParams) {
        commit("isLoading", true);

        return userService.downloadExcelLeads(queryParams).then(
            (res) => {
                commit("isLoading", false);
                if (res.res.data) {
                    const d = new Date();
                    const text = d.toLocaleDateString();
                    const href = URL.createObjectURL(res.res.data);
                    const anchorElement = document.createElement("a");
                    anchorElement.id = "excel";
                    anchorElement.setAttribute("href", href);
                    anchorElement.setAttribute(
                        "download",
                        `CityDashboard_${text}_export.xlsx`
                    );
                    document.body.appendChild(anchorElement);
                    anchorElement.click();
                    document.body.removeChild(anchorElement);
                    dispatch("alert/success", `Excel is been downloaded3`, {
                        root: true,
                    });
                } else {
                    let successMsg = localStorage.getItem('locale')==='en' ? "Email will be sent when the exported file is ready" : "Zodra de export gereed is ontvangt u een e-mail"
                    dispatch(
                        "alert/success",
                        `${successMsg}`,
                        {
                            root: true,
                        }
                    );
                }
            },
            () => {
                commit("isLoading", false);
                dispatch(
                    "alert/information",
                    `Sorry we can only download excel when number of leads are less then 5000`,
                    { root: true }
                );
            }
        );
    },
    getLocationListAction({ dispatch }, queryParams) {
        return userService.getLocationList(queryParams).then(
            (res) => {
                return res;
            },
            (error) => {
                dispatch("alert/error", `No data found at page`, { root: true });
                return error;
            }
        );
    },
    getALlLocationListAction({ dispatch }, queryParams) {
        return userService.getALlLocationList(queryParams).then(
            (res) => {
                return res;
            },
            (error) => {
                dispatch("alert/error", `No data found at page`, { root: true });
                return error;
            }
        );
    },
    getUserCitiesAction({ dispatch }, queryParams) {
        return userService.getUserCities(queryParams).then(
            (res) => {
                return res;
            },
            (error) => {
                dispatch("alert/error", `Cannot fetch city of this user`, {
                    root: true,
                });
                return error;
            }
        );
    },
    getUserActionsAction({ dispatch }, queryParams) {
        return userService.getUserActions(queryParams).then(
            (res) => {
                return res;
            },
            (error) => {
                dispatch("alert/error", `Cannot fetch city of this user`, {
                    root: true,
                });
                return error;
            }
        );
    },
    getUserLocationAction({ dispatch }, queryParams) {
        return userService.getUserLocation(queryParams).then(
            (res) => {
                return res;
            },
            (error) => {
                dispatch("alert/error", `Cannot fetch city of this user`, {
                    root: true,
                });
                return error;
            }
        );
    },
    createLocationAction({ dispatch }, payload) {
        return userService.createLocation(payload).then(
            (res) => {
                return res;
            },
            (err) => {
                err.res.data.message
                    ? dispatch("alert/error", `${err.res.data.message}`, {
                        root: true,
                    })
                    : dispatch("alert/error", `Error creating location`, {
                        root: true,
                    });
                return err;
            }
        );
    },
    createLeadPartnerAction({ dispatch }, payload) {
        return userService.createLeadPartner(payload).then(
            (res) => {
                return res;
            },
            (err) => {
                err.res.data.message
                    ? dispatch("alert/error", `${err.res.data.message}`, {
                        root: true,
                    })
                    : dispatch("alert/error", `Error creating LeadPartner`, {
                        root: true,
                    });
                return err;
            }
        );
    },
    editLocationAction({ dispatch }, payload) {
        return userService.editLocation(payload).then(
            (res) => {
                res.res.data.message
                    ? dispatch("alert/success", `${res.res.data.message}`, {
                        root: true,
                    })
                    : dispatch("alert/success", `Location saved successfully`, {
                        root: true,
                    });
                return res;
            },
            (err) => {
                err.res.data.message
                    ? dispatch("alert/error", `${err.res.data.message}`, {
                        root: true,
                    })
                    : dispatch("alert/error", `Error updating location`, {
                        root: true,
                    });
                return err;
            }
        );
    },
    updateLeadPartnerAction({ dispatch }, payload) {
        return userService.updateLeadPartner(payload).then(
            (res) => {
                res.res.data.message
                    ? dispatch("alert/success", `${res.res.data.message}`, {
                        root: true,
                    })
                    : dispatch("alert/success", `LeadPartner saved successfully`, {
                        root: true,
                    });
                return res;
            },
            (err) => {
                err.res.data.message
                    ? dispatch("alert/error", `${err.res.data.message}`, {
                        root: true,
                    })
                    : dispatch("alert/error", `Error updating LeadPartner`, {
                        root: true,
                    });
                return err;
            }
        );
    },
    getHouseScanDataAction({ commit }, queryParams) {
        return userService.getHouseScanData(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    updateHouseScanDataAction({ commit }, queryParams) {
        const houseHash = queryParams.house_hash;
        // eslint-disable-next-line no-param-reassign
        delete queryParams.house_hash;
        return userService.updateHouseScanData(houseHash, queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getProjectStatusListAction({ commit }, queryParams) {
        return userService.getProjectStatusList(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    updateAdvisuresScanAction({ commit }, queryParams) {
        const houseHash = queryParams.house_hash;
        // eslint-disable-next-line no-param-reassign
        delete queryParams.house_hash;
        return userService.updateAdvisuresScan(houseHash, queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getStreetInformation({ dispatch }, reqData) {
        // dispatch("alert/information", `Fetching street information from api.`, {
        //     root: true,
        // });
        let url = "";
        reqData.house_letter
            ? (url = `https://api.bag.kadaster.nl/lvbag/individuelebevragingen/v2/adressen?postcode=${reqData.postcode}&huisnummer=${reqData.huisnummer}&huisletter=${reqData.house_letter}`)
            : (url = `https://api.bag.kadaster.nl/lvbag/individuelebevragingen/v2/adressen?postcode=${reqData.postcode}&huisnummer=${reqData.huisnummer}`);
        return userService.getStreetInfo(url).then(
            (res) => {
                const token = localStorage.getItem("userToken");
                axios.defaults.headers.common.Authorization = `Token ${token}`;
                dispatch("alert/success", `Street information fetched.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                const token = localStorage.getItem("userToken");
                axios.defaults.headers.common.Authorization = `Token ${token}`;
                return error;
            }
        );
    },
    getConstructionYear({ dispatch }, url) {
        return userService.getConstructionYear(url).then(
            (res) => {
                const token = localStorage.getItem("userToken");
                axios.defaults.headers.common.Authorization = `Token ${token}`;
                dispatch("alert/success", `Fetched construction year.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                const token = localStorage.getItem("userToken");
                axios.defaults.headers.common.Authorization = `Token ${token}`;
                return error;
            }
        );
    },
    getLivingSpace({ dispatch }, url) {
        return userService.getLivingSpace(url).then(
            (res) => {
                const token = localStorage.getItem("userToken");
                axios.defaults.headers.common.Authorization = `Token ${token}`;
                dispatch("alert/success", `Fetched living space year.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                const token = localStorage.getItem("userToken");
                axios.defaults.headers.common.Authorization = `Token ${token}`;
                return error;
            }
        );
    },
    getHousePassportMeasurements({ dispatch }, queryParams) {
        return userService.getHousePassportMeasurementList(queryParams).then(
            (res) => {
                dispatch("alert/success", measurementFetchMsg, {
                    root: true,
                });
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getContactListHousePassportAction({ dispatch }, hash) {
        return userService.getContactsListHousePassport(hash).then(
            (res) => {
                dispatch("alert/success", measurementFetchMsg, {
                    root: true,
                });
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getContactsListAction({ commit }, reqBody = null) {
        return userService.getContactsList(reqBody).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getAllLabelsListAction({ commit }) {
        return userService.getAllLabelsList().then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getAllProjectsAction({ commit }) {
        return userService.getAllProjects().then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getContactsFromPassportListAction({ commit }, reqBody = null) {
        return userService.getContactsFromPassportList(reqBody).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getReviewListAction({ commit }, reqBody = null) {
        return userService.getReviewList(reqBody).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getEnergyReportListAction({commit}, reqBody = null) {
        return userService.getEnergyReportList(reqBody).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    createReviewAction({ dispatch }, reqData) {
        return userService.createReview(reqData).then(
            (res) => {
                dispatch("alert/success", `Review  created Succesfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    noQuoteEmailEmployeeAction({ dispatch }, reqData) {
        return userService.noQuoteEmailEmployee(reqData).then(
            (res) => {
                return res;
            },
            (error) => {
                return error;
            }
        );
    },

    getAllContactsList({ commit }, reqBody) {
        return userService.getAllContactsList(reqBody).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    createContactAction({ dispatch }, reqData) {
        return userService.createContact(reqData).then(
            (res) => {
                dispatch("alert/success", `Contact successfully created.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    createMeasurementAction({ dispatch }, reqData) {
        return userService.createMeasurement(reqData).then(
            (res) => {
                dispatch("alert/success", `Measurements successfully created.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    updateBulkLeadAction({ dispatch }, reqData) {
        return userService.updateBulkLead(reqData).then(
            (res) => {
                dispatch("alert/success", `Lead updated successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                return error;
            }
        );
    },

    updateContactAction({ dispatch }, reqData) {
        return userService.updateContact(reqData).then(
            (res) => {
                dispatch("alert/success", `Contact updated successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    updateMeasurementAction({ dispatch }, reqData) {
        return userService.updateMeasurement(reqData).then(
            (res) => {
                dispatch("alert/success", `Measure updated successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot update measure, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    deleteContactAction({ dispatch }, reqData) {
        return userService.deleteContact(reqData).then(
            (res) => {
                dispatch("alert/success", `Contact deleted successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot delete contact, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    deleteMeasurementAction({ dispatch }, reqData) {
        return userService.deleteMeasurement(reqData).then(
            (res) => {
                dispatch("alert/success", `Measurement deleted successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot delete measurement, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    createHousePassportAction({ dispatch, commit }, reqData) {
        return userService.createHousePassport(reqData).then(
            (res) => {
                dispatch("alert/success", housepasspoerCreationSuccessMsg, {
                    root: true,
                });
                commit("setHouseHash", res.res.data);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot create contact, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    createEnergyReportAction({dispatch, commit}, reqData) {
        return userService.createEnergyReport(reqData).then(
            (res) => {
                dispatch("alert/success", EnergyReportCreationSuccessMsg, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot create Energy Report, ${error.res.data.name}.`,
                    {root: true}
                );
                return error;
            }
        );
    },
    getBrandListAction({ commit }, reqData) {
        return userService.getBrandList(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    createBrandAction({ dispatch, commit }, reqData) {
        return userService.createBrand(reqData).then(
            (res) => {
                dispatch("alert/success", `Brand created successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot create brand, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    updateBrandAction({ commit, dispatch }, reqData) {
        return userService.updateBrand(reqData).then(
            (res) => {
                dispatch("alert/success", `Brand updated successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot update brand, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    deleteBrandAction({ dispatch }, reqData) {
        return userService.deleteBrand(reqData).then(
            (res) => {
                dispatch("alert/success", `Brand deleted successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot delete brand, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },

    getModelListAction({ commit }, reqData) {
        return userService.getModelList(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    getStatusListAction({ commit }, reqData) {
        return userService.getStatusList(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    getLabelsListAction({ commit }, reqData) {
        return userService.getLabelsList(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },

    getAllBrandListAction() {
        return userService.getAllBrandList().then(
            (res) => {
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getAllModelListAction() {
        return userService.getAllModelList().then(
            (res) => {
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    createModelAction({ dispatch, commit }, reqData) {
        return userService.createModel(reqData).then(
            (res) => {
                dispatch("alert/success", `Model created successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot create model, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    createStatusAction({ dispatch, commit }, reqData) {
        return userService.createStatus(reqData).then(
            (res) => {
                dispatch("alert/success", `Status created successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot create status, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    updateModelAction({ commit, dispatch }, reqData) {
        return userService.updateModel(reqData).then(
            (res) => {
                dispatch("alert/success", `Brand updated successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot update brand, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    deleteModelAction({ dispatch }, reqData) {
        return userService.deleteModel(reqData).then(
            (res) => {
                dispatch("alert/success", `Model deleted successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot delete model, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    createLabelAction({ dispatch, commit }, reqData) {
        return userService.createLabel(reqData).then(
            (res) => {
                dispatch("alert/success", `Label created successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot create status, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    updateLabelAction({ commit, dispatch }, reqData) {
        return userService.updateLabel(reqData).then(
            (res) => {
                dispatch("alert/success", `Brand updated successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot update brand, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    deleteLabelAction({ dispatch }, reqData) {
        return userService.deleteLabel(reqData).then(
            (res) => {
                dispatch("alert/success", `Model deleted successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot delete model, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    getActionListAction({ commit }, reqData) {
        return userService.getActionList(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    getProjectListAction({ commit }, reqData) {
        return userService.getProjectList(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    getLeadStatusAction({ commit }) {
        return userService.getLeadStatus().then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    deleteProjectStatusAction({ dispatch }, reqData) {
        return userService.deleteProjectStatus(reqData).then(
            (res) => {
                dispatch("alert/success", `Action deleted successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot delete action, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    createProjectStatusAction({ dispatch, commit }, reqData) {
        return userService.createProjectStatus(reqData).then(
            (res) => {
                dispatch("alert/success", `Project status created successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot create Action, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    updateProjectStatusAction({ commit, dispatch }, reqData) {
        return userService.updateProjectStatus(reqData).then(
            (res) => {
                dispatch("alert/success", `Action updated successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot update action, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    getProjectStatusAction({ commit }, params) {
        return userService.getProjectStatus(params).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    getProjectsAction({ commit }) {
        return userService.getProjects().then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    getAllProjectStatusAction({ commit }) {
        return userService.getAllProjectStatus().then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },

    getLeadPartnersAction({ commit }, reqData) {
        return userService.getLeadPartners(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    getAllLeadPartnersListAction({ commit }, reqData) {
        return userService.getAllLeadPartnersList(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    createActionsAction({ dispatch, commit }, reqData) {
        return userService.createActions(reqData).then(
            (res) => {
                dispatch("alert/success", `Action created successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot create Action, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    createProjectAction({ dispatch, commit }, reqData) {
        return userService.createProject(reqData).then(
            (res) => {
                dispatch("alert/success", `Project created successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot create Project, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    createLeadStatusAction({ dispatch, commit }, reqData) {
        return userService.createLeadStatus(reqData).then(
            (res) => {
                dispatch("alert/success", `Lead status created successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot create lead status, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    updateActionsAction({ commit, dispatch }, reqData) {
        return userService.updateActions(reqData).then(
            (res) => {
                dispatch("alert/success", `Action updated successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot update action, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    updateProjectAction({ commit, dispatch }, reqData) {
        return userService.updateProject(reqData).then(
            (res) => {
                dispatch("alert/success", `Project updated successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot update Project, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    updateLeadStatusAction({ commit, dispatch }, reqData) {
        return userService.updateLeadStatus(reqData).then(
            (res) => {
                dispatch("alert/success", `Lead status updated successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot update lead status, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    updateProFormaAction({ commit }, reqData) {
        return userService.updateProForma(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },

    updatePartnerAction({ commit, dispatch }, reqData) {
        return userService.updatePartner(reqData).then(
            (res) => {
                dispatch("alert/success", partnerUpdateSuccessMsg, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot update partner, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    deleteAction({ dispatch }, reqData) {
        return userService.deleteAction(reqData).then(
            (res) => {
                dispatch("alert/success", `Action deleted successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot delete action, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    deleteProject({ dispatch }, reqData) {
        return userService.deleteProject(reqData).then(
            (res) => {
                dispatch("alert/success", `Project deleted successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot delete project, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    createPartnerAction({ dispatch }, payload) {
        return userService.createPartner(payload).then(
            (res) => {
                dispatch("alert/success", `Partner successfully added.`, {
                    root: true,
                });
                return res;
            },

            (err) => {
                dispatch("alert/error", `Error while adding partner.`, { root: true });
                return err;
            }
        );
    },
    getPartnersListAction({ commit }, reqData) {
        return userService.getPartnersList(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    getPartnersDetailAction({ commit }, reqData) {
        return userService.getPartnersDetail(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    checkZipcodeAction({ commit }, reqData) {
        return userService.checkZipcode(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    getAllPartnersListAction({ commit }, reqData) {
        return userService.getAllPartners(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    getAllAdvisorsListAction({commit}, reqData) {
        return userService.getAllAdvisors(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    updateEnergyReportDetailAction({commit}, payload) {
        const {id} = payload;
        // eslint-disable-next-line no-param-reassign
        delete payload.id;
        return userService.updateEnergyReportDetail(payload, id).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    sendEnergyReportCustomerAction({commit}, queryParams) {
        const {id} = queryParams;
        // eslint-disable-next-line no-param-reassign
        delete queryParams.id;
        return userService.sendEnergyReportCustomer(queryParams, id).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    generateReportPdfAction({commit}, queryParams) {
        const {id} = queryParams;
        // eslint-disable-next-line no-param-reassign
        delete queryParams.id;
        return userService.generateReportPdf(id).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    // Energy report detail view
    getEnergyReportDetailAction({commit}, queryParams) {
        return userService.getEnergyReportDetail(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getEnergyReportQuestionsAction({commit}, queryParams) {
        return userService.getEnergyReportQuestions(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    updateEnergyReportAction({commit}, payload) {
        return userService.updateEnergyReport(payload).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    deletePartnerAction({ dispatch }, reqData) {
        return userService.deletePartner(reqData).then(
            (res) => {
                dispatch("alert/success", `Partner deleted successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot delete partner, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    getPartnerPaymentInfo({ dispatch, commit }, queryparams) {
        return userService.partnerPaymentInfo(queryparams).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot create Mapping, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    getProFormaAction({ commit }, reqData) {
        return userService.getProForma(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    getProformaPreviewPdfAction({ commit }, params) {
        return userService.getProformaPreviewPdf(params).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    uploadProformaPdfAction({ commit }, params) {
        return userService.uploadProformaPdf(params).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    getProFormaPaymentInfo({ dispatch, commit }) {
        return userService.proFormaPaymentInfo().then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot create Mapping, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    createProFormaMeasureFeesAction({ dispatch, commit }, reqData) {
        return userService.createProFormaMeasureFees(reqData).then(
            (res) => {
                dispatch("alert/success", `Measure created successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch("alert/error", `Cannot create measure.`, { root: true });
                commit("isLoading", true);
                return error;
            }
        );
    },
    updateProFormaMeasureFeesAction({ commit, dispatch }, reqData) {
        return userService.updateProFormaMeasureFees(reqData).then(
            (res) => {
                dispatch("alert/success", `Measure updated successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch("alert/error", `Cannot update measure.`, { root: true });
                commit("isLoading", true);
                return error;
            }
        );
    },

    deleteProFormaMeasureFeesAction({ dispatch }, reqData) {
        return userService.deleteProFormaMeasureFees(reqData).then(
            (res) => {
                dispatch("alert/success", `Measure deleted successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot delete measure, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },

    createProFormaBrandFeesAction({ dispatch, commit }, reqData) {
        return userService.createProFormaBrandFees(reqData).then(
            (res) => {
                dispatch("alert/success", `Brand created successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch("alert/error", `Cannot create brand.`, { root: true });
                commit("isLoading", true);
                return error;
            }
        );
    },
    updateProFormaBrandFeesAction({ commit, dispatch }, reqData) {
        return userService.updateProFormaBrandFees(reqData).then(
            (res) => {
                dispatch("alert/success", `Brand updated successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch("alert/error", `Cannot update brand.`, { root: true });
                commit("isLoading", true);
                return error;
            }
        );
    },
    deleteProFormaBrandFeesAction({ dispatch }, reqData) {
        return userService.deleteProFormaBrandFees(reqData).then(
            (res) => {
                dispatch("alert/success", `Brand deleted successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot delete brand, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    createProFormaModelFeesAction({ dispatch, commit }, reqData) {
        return userService.createProFormaModelFees(reqData).then(
            (res) => {
                dispatch("alert/success", `Model created successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch("alert/error", `Cannot create model.`, { root: true });
                commit("isLoading", true);
                return error;
            }
        );
    },

    updateProFormaModelFeesAction({ commit, dispatch }, reqData) {
        return userService.updateProFormaModelFees(reqData).then(
            (res) => {
                dispatch("alert/success", `Model updated successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch("alert/error", `Cannot update model.`, { root: true });
                commit("isLoading", true);
                return error;
            }
        );
    },
    deleteProFormaModelFeesAction({ dispatch }, reqData) {
        return userService.deleteProFormaModelFees(reqData).then(
            (res) => {
                dispatch("alert/success", `Model deleted successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot delete model, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },

    createPartnerMeasureFeesAction({ dispatch, commit }, reqData) {
        return userService.createPartnerMeasureFees(reqData).then(
            (res) => {
                dispatch("alert/success", `Model created successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot create model, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    updatePartnerMeasureFeesAction({ commit, dispatch }, reqData) {
        return userService.updatePartnerMeasureFees(reqData).then(
            (res) => {
                dispatch("alert/success", `Brand updated successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot update brand, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    deletePartnerMeasureFeesAction({ dispatch }, reqData) {
        return userService.deletePartnerMeasureFees(reqData).then(
            (res) => {
                dispatch("alert/success", `Model deleted successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot delete model, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    createPartnerBrandFeesAction({ dispatch, commit }, reqData) {
        return userService.createPartnerBrandFees(reqData).then(
            (res) => {
                dispatch("alert/success", `Model created successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot create model, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    updatePartnerBrandFeesAction({ commit, dispatch }, reqData) {
        return userService.updatePartnerBrandFees(reqData).then(
            (res) => {
                dispatch("alert/success", `Brand updated successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot update brand, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    deletePartnerBrandFeesAction({ dispatch }, reqData) {
        return userService.deletePartnerBrandFees(reqData).then(
            (res) => {
                dispatch("alert/success", `Model deleted successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot delete model, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    createPartnerModelFeesAction({ dispatch, commit }, reqData) {
        return userService.createPartnerModelFees(reqData).then(
            (res) => {
                dispatch("alert/success", `Model created successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot create model, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },

    updatePartnerModelFeesAction({ commit, dispatch }, reqData) {
        return userService.updatePartnerModelFees(reqData).then(
            (res) => {
                dispatch("alert/success", `Brand updated successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot update brand, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    deletePartnerModelFeesAction({ dispatch }, reqData) {
        return userService.deletePartnerModelFees(reqData).then(
            (res) => {
                dispatch("alert/success", `Model deleted successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot delete model, ${error.res.data.name}.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    getNotesListAction({ commit }, reqData) {
        commit("isLoading", true);
        return userService.getNotesList(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getOutlookCalendarEventsAction({ commit }, reqData) {
        commit("isLoading", true);
        return userService.getOutlookCalendarEvents(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    createNoteAction({ dispatch, commit }, reqData) {
        return userService.createNote(reqData).then(
            (res) => {
                dispatch("alert/success", `Note created successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch("alert/error", `Cannot create note, ${error.res.data.name}.`, {
                    root: true,
                });
                commit("isLoading", true);
                return error;
            }
        );
    },
    updateNoteAction({ dispatch, commit }, reqData) {
        return userService.updateNote(reqData).then(
            (res) => {
                dispatch("alert/success", `Note updated successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch("alert/error", `Cannot update note, ${error.res.data.name}.`, {
                    root: true,
                });
                commit("isLoading", true);
                return error;
            }
        );
    },
    getCallListAction({ commit }, reqData) {
        commit("isLoading", true);
        return userService.getCallLog(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    createCallLogAction({ dispatch, commit }, reqData) {
        return userService.createCallLog(reqData).then(
            (res) => {
                dispatch("alert/success", `Call Log created successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch("alert/error", `Cannot create call log, ${error.res.data.name}.`, {
                    root: true,
                });
                commit("isLoading", true);
                return error;
            }
        );
    },
    updateCallLogAction({ dispatch, commit }, reqData) {
        return userService.updateCallLog(reqData).then(
            (res) => {
                dispatch("alert/success", `Call Log updated successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch("alert/error", `Cannot update call log, ${error.res.data.name}.`, {
                    root: true,
                });
                commit("isLoading", true);
                return error;
            }
        );
    },
    getOrCreateProformaAction({ commit }, reqData) {
        return userService.getOrCreateProforma(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    getLeadQuotationAction({ commit }, queryParams) {
        return userService.getLeadQuotation(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getLeadHistoryAction({ commit }, queryParams) {
        return userService.getLeadHistory(queryParams).then(
            (res) => {
                // commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getLeadHistoryDiffAction({ commit }, queryParams) {
        return userService.getLeadHistoryDiff(queryParams).then(
            (res) => {
                // commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },

    getLeadAction({ commit }, leadId) {
        return userService.getLeadFromId(leadId).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    createQuotationAction({ dispatch, commit }, reqData) {
        return userService.createQuotation(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot create quotation, ${error.res.data.name}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    updateQuotationAction({ commit, dispatch }, reqData) {
        return userService.updateQuotation(reqData).then(
            (res) => {
                dispatch("alert/success", `Quotation  saved successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch("alert/error", `Error while saving quotation.`, {
                    root: true,
                });
                commit("isLoading", true);
                return error;
            }
        );
    },
    uploadDocumentAction({ commit, dispatch }, reqData) {
        return userService.uploadDocument(reqData).then(
            (res) => {
                dispatch("alert/success", `Document  saved successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch("alert/error", `Error while saving Document.`, {
                    root: true,
                });
                commit("isLoading", true);
                return error;
            }
        );
    },
    DocumentListAction({ commit }, reqData) {
        return userService.getDocuments(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    DocumentDeleteAction({ commit }, id) {
        return userService.deleteDocuments(id).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    uploadPartnerDocumentAction({ commit, dispatch }, reqData) {
        return userService.uploadPartnerDocument(reqData).then(
            (res) => {
                dispatch("alert/success", `Document  saved successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch("alert/error", `Error while saving Document.`, {
                    root: true,
                });
                commit("isLoading", true);
                return error;
            }
        );
    },
    DocumentPartnerListAction({ commit }, reqData) {
        return userService.getPartnerDocuments(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    DocumentPartnerDeleteAction({ commit }, id) {
        return userService.deletePartnerDocuments(id).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    getLeadFeeAction({ commit }, queryParams) {
        return userService.getLeadFee(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    sendMailToPartnerAction({ commit }, queryParams) {
        return userService.sendMailToPartner(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getPartnersAction({ commit }, queryParams) {
        return userService.getPartners(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    exportPartnerInvoiceAction({ commit, dispatch }, queryParams) {
        return userService.exportPartnerInvoice(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                const d = new Date();
                const text = d.toLocaleDateString();
                const href = URL.createObjectURL(res.res.data);
                const anchorElement = document.createElement("a");
                anchorElement.id = "excel";
                anchorElement.setAttribute("href", href);
                anchorElement.setAttribute(
                    "download",
                    `PartnerInvoice_${text}_export.xlsx`
                );
                document.body.appendChild(anchorElement);
                anchorElement.click();
                document.body.removeChild(anchorElement);
                dispatch("alert/success", `Partner invoice generated successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch("alert/error", `All leads are already billed.`, {
                    root: true,
                });
                return error;
            }
        );
    },
    getPartnerInvoicesAction({ commit }, queryParams) {
        return userService.getPartnerInvoices(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getContactFromHouseDetailsAction({ commit }, queryParams) {
        return userService.getContactsFromHouse(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    sendQuotationAction({ dispatch }, queryParams) {
        return userService.sendQuotation(queryParams).then(
            (res) => {
                dispatch("alert/success", `Quotation  sent to user successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch("alert/error", `Error  sending quotation to user.`, {
                    root: true,
                });
                return error;
            }
        );
    },
    changeLeadStatusAction({ commit, dispatch }, reqData) {
        return userService.changeLeadStatus(reqData).then(
            (res) => {
                dispatch(
                    "alert/success",
                    `Lead status changed successfully to ${reqData.lead_status}.`,
                    {
                        root: true,
                    }
                );
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch("alert/error", `Error while saving lead status.`, {
                    root: true,
                });
                commit("isLoading", true);
                return error;
            }
        );
    },
    getOrdersListAction({ commit }, queryParams) {
        return userService.getOrdersList(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getOrdersListChartsAction({ commit }, queryParams) {
        return userService.getOrdersListCharts(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getOrderDetailAction({ commit }, queryParams) {
        return userService.getOrderDetail(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    createOrderAction({ dispatch }, reqData) {
        return userService.createOrder(reqData).then(
            (res) => {
                dispatch("alert/success", `Order successfully created.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot create order, ${
                        error.res.data.lead_created_date || error.res.data.zipcode
                    }.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    updateOrderAction({ dispatch }, reqData) {
        return userService.updateOrder(reqData).then(
            (res) => {
                dispatch("alert/success", `Order successfully updated.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot update order, ${
                        error.res.data.order_created_date || error.res.data.zipcode
                    }.`,
                    { root: true }
                );
                return error;
            }
        );
    },
    viewQuotationAction({ commit }, queryParams) {
        return userService.viewQuotation(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    acceptProformsAction({ commit }, queryParams) {
        return userService.acceptProforma(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getConfigurationAction({ commit }) {
        return userService.getConfiguration().then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    getPDFRowDataAction({commit}, energyReportID) {
        return userService.getPDFRowData(energyReportID).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    saveReportPDFAction({commit, dispatch }, payload) {
        return userService.saveReportPDF(payload).then(
            (res) => {
                // dispatch("alert/success", `PDF will be generated within few minutes`, {
                //     root: true,
                // });
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    uplaodImageToS3Action({commit, dispatch }, payload) {
        return userService.uplaodImageToS3(payload).then(
            (res) => {
                // dispatch("alert/success", `PDF will be generated within few minutes`, {
                //     root: true,
                // });
                return res;
            },
            (error) => {
                return error;
            }
        );
    },

    updateConfigurationsAction({ commit, dispatch }, queryParams) {
        return userService.updateConfigurations(queryParams).then(
            (res) => {
                commit("isLoading", false);
                dispatch("alert/success", `Days updated successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                commit("isLoading", true);
                dispatch("alert/error", `Error updating days  .`, {
                    root: true,
                });
                return error;
            }
        );
    },
    createConfigurationsAction({ commit, dispatch }, queryParams) {
        return userService.createConfigurations(queryParams).then(
            (res) => {
                commit("isLoading", false);
                dispatch("alert/success", `Days created successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                commit("isLoading", true);
                dispatch("alert/error", `Error creating days  .`, {
                    root: true,
                });
                return error;
            }
        );
    },
    getLocationCitiesAction({ commit }, queryParms) {
        return userService.getLocationCities(queryParms).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    createLocationCitiesAction({ dispatch, commit }, reqData) {
        return userService.createLocationCities(reqData).then(
            (res) => {
                commit("isLoading", false);
                dispatch("alert/success", `City added successfully.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch("alert/error", `Cannot add city, ${error.res.data.message}.`, {
                    root: true,
                });
                commit("isLoading", true);
                return error;
            }
        );
    },
    deleteLocationCityAction({ dispatch, commit }, reqData) {
        return userService.deleteLocationCity(reqData).then(
            (res) => {
                commit("isLoading", false);
                dispatch("alert/success", `${res.res.data.message}`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot delete city, ${error.res.data.message}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },
    deleteEmployeeAction({ dispatch, commit }, reqData) {
        return userService.deleteEmployee(reqData).then(
            (res) => {
                commit("isLoading", false);
                dispatch("alert/success", `${res.res.data.message}`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch(
                    "alert/error",
                    `Cannot delete employee, ${error.res.data.message}.`,
                    { root: true }
                );
                commit("isLoading", true);
                return error;
            }
        );
    },

    getEmployessListAction({ commit }, queryParms) {
        return userService.getEmployessList(queryParms).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                commit("isLoading", true);
                return error;
            }
        );
    },
    getCalendarEventsAction({ commit }, reqData) {
        commit("isLoading", true);
        return userService.getCalendarEvents(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getTaskCalendarEventsAction({ commit }, reqData) {
        commit("isLoading", true);
        return userService.getTaskCalendarEvents(reqData).then(
            (res) => {
                commit("isLoading", false);
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    createCalendarEventAction({ dispatch }, reqData) {
        return userService.createCalendarEvent(reqData).then(
            (res) => {
                dispatch("alert/success", `Event successfully created.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    createCalendarAtHpAction({ dispatch }, reqData) {
        return userService.createCalendarAtHp(reqData).then(
            (res) => {
                dispatch("alert/success", `Event successfully created at house.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    updateCalendarEventAction({ dispatch }, reqData) {
        return userService.updateCalendarEvent(reqData).then(
            (res) => {
                dispatch("alert/success", `Event successfully updated.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    updateCalendarAtHpAction({ dispatch }, reqData) {
        return userService.updateCalendarAtHp(reqData).then(
            (res) => {
                dispatch("alert/success", `Event successfully updated at house.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    deleteCalendarEventsAction({ dispatch, commit }, reqData) {
        dispatch("alert/information", "Deleting this event", {
            root: true,
        });
        return userService.deleteCalendarEvents(reqData).then(
            (res) => {
                commit("isLoading", false);
                dispatch("alert/success", "Deleted event successfully", {
                    root: true,
                });
                return res;
            },
            (error) => {
                dispatch("alert/error", `Cannot delete event`, { root: true });
                commit("isLoading", true);
                return error;
            }
        );
    },
    sendCustomerEmailForNewQuoteIncommingAction({ commit }, reqData) {
        return userService.sendCustomerEmailForNewQuoteIncomming(reqData).then(
            (res) => {
                commit("ignoringCommit", false);
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    updateLeadContactedAction({ dispatch }, reqData) {
        return userService.leadContacted(reqData).then(
            (res) => {
                dispatch("alert/success", `lead contacted successfully updated.`, {
                    root: true,
                });
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getAllUsersAction() {
        return userService.getAllUsers().then(
            (res) => {
                return res;
            },
            (error) => {
                return error;
            }
        );
    },


    TaskOrderUpdateAction({ dispatch, commit }, reqData) {
        return userService.TaskOrderUpdate(reqData).then(
            (res) => {
                dispatch("alert/success", `Task updated successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch("alert/error", `Something went wrong.`, { root: true });
                commit("isLoading", true);
                return error;
            }
        );
    },


    getArchivedTasksAction({commit},queryParams) {
        return userService.getArchivedTasks(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },

    ArchiveTaskAction({ dispatch, commit }, reqData) {
        return userService.ArchiveTask(reqData).then(
            (res) => {
                dispatch("alert/success", `Task archived successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch("alert/error", `Something went wrong.`, { root: true });
                commit("isLoading", true);
                return error;
            }
        );
    },


    TaskStatusUpdateAction({ dispatch, commit }, reqData) {
        return userService.TaskStatusUpdate(reqData).then(
            (res) => {
                dispatch("alert/success", `Task updated successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch("alert/error", `Something went wrong.`, { root: true });
                commit("isLoading", true);
                return error;
            }
        );
    },
    getTaskListDataAction({commit},queryParams) {
        return userService.getTaskListData(queryParams).then(
            (res) => {
                return res;
            },
            (error) => {
                return error;
            }
        );
    },

    getTaskStatusListDataAction() {
        return userService.getTaskStatusListData().then(
            (res) => {
                return res;
            },
            (error) => {
                return error;
            }
        );
    },

    createTaskAction({ dispatch, commit }, reqData) {
        return userService.createTask(reqData).then(
            (res) => {
                dispatch("alert/success", `Task created successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch("alert/error", `${error.res.data.message}.`, { root: true });
                commit("isLoading", true);
                return error;
            }
        );
    },
    updateTaskAction({ dispatch, commit }, reqData) {
        return userService.updateTask(reqData).then(
            (res) => {
                dispatch("alert/success", `Task updated successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch("alert/error", `${error.res.data.message}.`, { root: true });
                commit("isLoading", true);
                return error;
            }
        );
    },

    uploadTaskDocumentAction({ commit, dispatch }, reqData) {
        return userService.uploadTaskDocument(reqData).then(
            (res) => {
                dispatch("alert/success", `Attachment  saved successfully.`, {
                    root: true,
                });
                commit("isLoading", false);
                return res;
            },
            (error) => {
                dispatch("alert/error", `Error while saving Attachment.`, {
                    root: true,
                });
                commit("isLoading", true);
                return error;
            }
        );
    },

    getTaskAttachmentListAction({commit},queryParams) {
        return userService.getTaskAttachmentList(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getUserLogsAction({commit},queryParams) {
        return userService.getUserLogs(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    deleteAttachmentAction({ dispatch }, reqData) {
        return userService.deleteAttachment(reqData).then(
            (res) => {
                dispatch("alert/success", `Task Attachment Successfully Deleted.`, { root: true });
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getCommentListAction({commit},queryParams) {
        return userService.getCommentList(queryParams).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    createTaskCommentAction({commit},reqData) {
        return userService.createTaskComment(reqData).then(
            (res) => {
                commit("ignoringCommit");
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getLeadsFromHouseAction({ dispatch }, hash) {
        return userService.getLeadsFromHouse(hash).then(
            (res) => {
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getTaskOverviewAction({ dispatch },) {
        return userService.getTaskOverview().then(
            (res) => {
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    getAdvisorNotificationAction({ dispatch },queryParams) {
        return userService.getAdvisorNotification(queryParams).then(
            (res) => {
                return res;
            },
            (error) => {
                return error;
            }
        );
    },
    markAsReadAdvisorNotificationAction({ dispatch },payload) {
        return userService.markAsReadAdvisorNotification(payload).then(
            (res) => {
                return res;
            },
            (error) => {
                return error;
            }
        );
    },

};

const mutations = {
    setLoginSuccess(state, res) {
        state.status.loggedIn = true;
        state.user.token = res.key;
        state.expiresAt = new Date(res.expires_at);
        state.user.email = res.user.email;
        state.user.group = res.user.group;
        state.user.isActionUser = res.user.is_action_user;
        state.user.isProjectUser = res.user.is_project_user;
        state.user.name = `${res.user.first_name} ${res.user.last_name}`;
        state.user.initials =
            res.user.first_name.slice(0, 1) + res.user.last_name.slice(0, 1);
        localStorage.setItem("email", state.user.email);
        localStorage.setItem("actionUser", state.user.isActionUser);
        localStorage.setItem("tokenExpireAt", state.expiresAt);
        localStorage.setItem("projectUser", state.user.isProjectUser);
        localStorage.setItem("userToken", state.user.token);
        localStorage.setItem("userName", state.user.name);
        localStorage.setItem("userInitial", state.user.initials);
        localStorage.setItem("group", state.user.group.group_code);
        localStorage.setItem("special_partner", res.user.is_special_partner);
        if (res.user.locations && res.user.locations.length) {
            const locationList = [];
            res.user.locations.forEach((location) => {
                locationList.push(location.id);
            });
            localStorage.setItem("locations", locationList);
        }
    },
    setPermisions(state, res) {
        res.user.group.permissions.forEach((obj) => {
            // Check if the codename is "Can view lead history"
            const permission = obj.codename === "view_lead_history"
                ? obj.codename
                : obj.codename.split("_")[0];

            // Check if the model_name already exists in the state.user.permissions object
            if (state.user.permissions[obj.model_name]) {
                state.user.permissions[obj.model_name].push(permission);
            } else {
                state.user.permissions[obj.model_name] = [permission];
            }
        });

        res.user.special_permissions.forEach((obj) => {
            // Check if the codename is "Can view lead history"
            const permission = obj.codename === "view_lead_history"
                ? obj.codename
                : obj.codename.split("_")[0];

            // Check if the model_name already exists in the state.user.permissions object
            if (state.user.permissions[obj.model_name]) {
                state.user.permissions[obj.model_name].push(permission);
            } else {
                state.user.permissions[obj.model_name] = [permission];
            }
        });
        localStorage.setItem("permissions", JSON.stringify(state.user.permissions));
    },
    setLoginFailure(state) {
        state.status.loggedIn = false;
        state.user.token = "";
        state.user.email = "";
    },
    setUserList(state, res) {
        state.usersList = res;
    },
    isLoading(state, res) {
        state.isLoading = res;
    },
    setAuthToken() {
        const token = localStorage.getItem("userToken");
        axios.defaults.headers.common.Authorization = `Token ${token}`;
    },
    setHouseHash(state, res) {
        state.houseHash = res.house_hash;
    },
    setCityDash(state, res) {
        state.cityDash = res;
    },
    setLeadDash(state, res) {
        state.leadDash = res;
    },
    setPartnerDash(state, res) {
        state.partnerDash = res;
    },
    setContactsFilter(state, res) {
        state.contactsFilter = res;
    },
    setHouseTypes(state, houseTypes) {
        state.houseTypes = houseTypes;
    },
    // this mutation is to just keep a commit at those actions who dosent have any first param,and have queryparam
    ignoringCommit() {},
};

// eslint-disable-next-line import/prefer-default-export
export const account = {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
};